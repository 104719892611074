export const SAVE_PAYMENT_METHOD = 'SAVE_PAYMENT_METHOD';
export const RESTORE_PAYMENT_METHOD = 'RESTORE_PAYMENT_METHOD';
export const CLEAR_PAYMENT_METHOD = 'CLEAR_PAYMENT_METHOD';

export function SetSelectedPaymentMethod(state: any = {}, action) {
  switch (action.type) {
    case SAVE_PAYMENT_METHOD:
      return action.payload;
    case RESTORE_PAYMENT_METHOD:
      return action.payload;
    case CLEAR_PAYMENT_METHOD:
      return {};
    default:
      return state;
  }
}
