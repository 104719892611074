import {Injectable, EventEmitter, Inject, PLATFORM_ID} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../store/reducers/appState';
import {Router} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import {RestaurantData} from '../store/actions/restaurant';
import {CLEAR, ADD_ITEM, UPDATE_QUANTITY, DELETE_ITEM, RESTORE_EXISTING_DATA} from '../store/reducers/cart';
import {SELECTED_DISH} from '../store/reducers/selected_dish';
import {SAVE_SELECTED_POLICY, RESTORE_SELECTED_POLICY, CLEAR_SELECTED_POLICY} from '../store/reducers/policyReducer';
import {SIGN_IN, RESTORE_USER, UPDATE_USER, REMOVE_USER} from '../store/reducers/signInReducer';
import {SET_ROUTE_PAGE} from '../store/reducers/routeReducer';
import {AVAILABLE_PROMOTION, RESTORE_AVAILABLE_PROMOTION, CLEAR_AVAILABLE_PROMOTION} from '../store/reducers/available_promotion';
import {ACCEPTED_PROMOTION, RESTORE_ACCEPTED_PROMOTION, CLEAR_ACCEPTED_PROMOTION} from '../store/reducers/accepted_promotion';
import {PUSH_DELIVERY_ADDRESS} from '../store/reducers/customer_delivery_address_reducer';
import {GENERATE_SCHEDULE} from '../store/reducers/schedule';
import {BOOKING} from '../store/reducers/bookingReducer';
import {STORE_DELIVERY_TIME, RESTORE_DELIVERY_TIME, CLEAR_DELIVERY_TIME} from '../store/reducers/deliveryTimeReducer';
import {VIEW_ITEM_MODAL} from '../store/reducers/common_modal_reducer';
import {SAVE_BOOKING_TIMES} from '../store/reducers/booking_times';
import {SAVE_PAYMENT_METHOD, RESTORE_PAYMENT_METHOD, CLEAR_PAYMENT_METHOD} from '../store/reducers/paymentMethodReducer';
import {SAVE_ORDER_NOTES, RESTORE_ORDER_NOTES, CLEAR_ORDER_NOTES} from '../store/reducers/orderNotesReducer';
import {_SAVE_DELIVERY_CHARGE} from '../store/reducers/delivery_charge_reducer';
import {_SAVE_PARCEL_INFO} from '../store/reducers/parcelReducer';
import {Md5} from 'ts-md5/dist/md5';


@Injectable()
export class StoreService {
  errors_data = new EventEmitter<any>();
  delivery_time2 = new EventEmitter<any>();
  editUserData = new EventEmitter<any>();
  signUpForm = new EventEmitter<any>();
  bookingProgress = new EventEmitter<any>();
  orderProgress = new EventEmitter<any>();
  confirmOrderUponAccept = new EventEmitter<any>();
  restaurantData: Observable<any>;
  cartData: Observable<any>;
  selectedDishData: Observable<any>;
  user: Observable<any>;
  selected_policy: Observable<any>;
  route_page: Observable<any>;
  delivery_address: Observable<any>;
  available_promotion: Observable<any>;
  accepted_promotion: Observable<any>;
  schedule: Observable<any>;
  bookingData: Observable<any>;
  delivery_time: Observable<any>;
  common_data: Observable<any>;
  booking_time_list: Observable<any>;
  paymentMethod: Observable<any>;
  orderNotes: Observable<any>;
  deliveryCharge: Observable<any>;
  parcelInformation: Observable<any>;


  constructor(@Inject(PLATFORM_ID) private readonly platformId: any, private router: Router,public db: Store<fromRoot.State>) {
    this.restaurantData = this.db.select(state => state.restaurant);
    this.cartData = this.db.select(state => state.cart);
    this.selectedDishData = this.db.select(state => state.selected_dish);
    this.user = this.db.select(state => state.user);
    this.selected_policy = this.db.select(state => state.selected_policy);
    this.route_page = this.db.select(state => state.route_page);
    this.delivery_address = this.db.select(state => state.delivery_address);
    this.available_promotion = this.db.select(state => state.available_promotion);
    this.accepted_promotion = this.db.select(state => state.accepted_promotion);
    this.schedule = this.db.select(state => state.schedule);
    this.bookingData = this.db.select(state => state.booking);
    this.delivery_time = this.db.select(state => state.delivery_time);
    this.common_data = this.db.select(state => state.common_data);
    this.booking_time_list = this.db.select(state => state.booking_time_list);
    this.paymentMethod = this.db.select(state => state.paymentMethod);
    this.orderNotes = this.db.select(state => state.orderNotes);
    this.deliveryCharge = this.db.select(state => state.deliveryCharge);
    this.parcelInformation = this.db.select(state => state.parcelInformation);
  }

  saveRestaurantData(data) {
    this.db.dispatch(new RestaurantData(data));
  }

  saveSelectedDish(data) {
    this.db.dispatch({type: SELECTED_DISH, payload: data});
  }

  addToCart(data) {
    this.db.dispatch({type: ADD_ITEM, payload: data});
    this.cartData.subscribe(resp => {
      if (resp) {
        if (this.platformId === 'browser') {
          sessionStorage.setItem('cartData', JSON.stringify(resp));
        }
      }
    });
  }

  updateToCart(dish, index) {
    this.db.dispatch({type: UPDATE_QUANTITY, payload: {dish: dish, index : index}});
    this.cartData.subscribe(resp => {
      if (resp) {
        if (this.platformId === 'browser') {
          sessionStorage.setItem('cartData', JSON.stringify(resp));
        }
      }
    });
  }

  removeToCart(cartIndex) {
    this.db.dispatch({type: DELETE_ITEM, payload: cartIndex});
    this.cartData.subscribe(resp => {
      if (resp) {
        if (this.platformId === 'browser') {
          sessionStorage.setItem('cartData', JSON.stringify(resp));
        }
      }
    });
  }

  storeExistingCartData() {
    if (this.platformId === 'browser') {
      if (sessionStorage.getItem('cartData') && sessionStorage.getItem('generated_cart_data')) {
        const data = JSON.parse(sessionStorage.getItem('cartData'));
        const generate_data = JSON.parse(sessionStorage.getItem('generated_cart_data'));
        
        const md5 = new Md5();
        let checksum = md5.appendStr(JSON.stringify(data)).end();
        if(checksum === generate_data) {
          this.db.dispatch({type: RESTORE_EXISTING_DATA, payload: data});
        } else {
          sessionStorage.removeItem('cartData');
          this.db.dispatch({type: CLEAR});
        }
      }
    }
  }

  saveLoginData(data) {
    this.db.dispatch({type: SIGN_IN, payload: data});
    this.user.subscribe(resp => {
      if (resp && resp['isLogged'] === true) {
        if (this.platformId === 'browser') {
          localStorage.setItem('user', JSON.stringify(resp['data']));
          localStorage.setItem('token', resp['token']);
        }
      } else {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
      }
    });
  }
  storeExistingLoginData() {
    if (this.platformId === 'browser') {
      if (localStorage.getItem('user') && localStorage.getItem('token')) {
        const user = JSON.parse(localStorage.getItem('user'));
        const token = localStorage.getItem('token');
        this.db.dispatch({type: RESTORE_USER, payload: {isLogged: true, data: user, token: token}});
      } else {
        this.db.dispatch({type: RESTORE_USER, payload: {isLogged: false}});
      }
    }
  }


  /**POLICY action**/
  saveSelectedPolicy(data) {
    this.db.dispatch({type: SAVE_SELECTED_POLICY, payload: data});
  }
  storeExistingSelectedPolicy() {
    if (this.platformId === 'browser') {
      if (sessionStorage.getItem('policy')) {
        const data = JSON.parse(sessionStorage.getItem('policy'));
        this.db.dispatch({type: RESTORE_SELECTED_POLICY, payload: data});
      }
    }
  }

  /**Set redirect page**/
  setRoutePage(data) {
    /* {page:'checkout', data: null}*/
    this.db.dispatch({type: SET_ROUTE_PAGE, payload: data});
  }

  saveBookingTimes(data) {
    /* {page:'checkout', data: null}*/
    this.db.dispatch({type: SAVE_BOOKING_TIMES, payload: data});
  }


  /**Save customer delivery address**/

  saveDeliveryAddress(data) {
    this.db.dispatch({type: PUSH_DELIVERY_ADDRESS, payload: data});
  }

  saveAvailablePromotion(data) {
    this.db.dispatch({type: AVAILABLE_PROMOTION, payload: data});
    this.available_promotion.subscribe(resp => {
      if (resp) {
        if (this.platformId === 'browser') {
          sessionStorage.setItem('availablePromoData', JSON.stringify(resp));
        }
      }
    });
  }
  storeExistingAvailablePromotion() {
    if (this.platformId === 'browser') {
      if (sessionStorage.getItem('availablePromoData')) {
        const data = JSON.parse(sessionStorage.getItem('availablePromoData'));
        this.db.dispatch({type: RESTORE_AVAILABLE_PROMOTION, payload: data});
      }
    }
  }

  saveAcceptedPromotion(data) {
    this.db.dispatch({type: ACCEPTED_PROMOTION, payload: data});
    this.accepted_promotion.subscribe(resp => {
      if (resp) {
        if (this.platformId === 'browser') {
          sessionStorage.setItem('acceptedPromoData', JSON.stringify(resp));
        }
      }
    });
  }
  storeExistingAcceptedPromotion() {
    if (this.platformId === 'browser') {
      if (sessionStorage.getItem('acceptedPromoData')) {
        const data = JSON.parse(sessionStorage.getItem('acceptedPromoData'));
        this.db.dispatch({type: RESTORE_ACCEPTED_PROMOTION, payload: data});
      }
    }
  }

  setDeliveryTime(time) {
    this.delivery_time2.emit(time);
  }

  saveSchedule(data) {
    this.db.dispatch({type: GENERATE_SCHEDULE, payload: data});
  }

  storeDeliveryTime(data) {
    this.db.dispatch({type: STORE_DELIVERY_TIME, payload: data});
    this.delivery_time.subscribe(resp => {
      if (resp) {
        if (this.platformId === 'browser') {
          sessionStorage.setItem('time', JSON.stringify(resp));
        }
      }
    });
  }
  storeExistingDeliveryTime(){
    if (this.platformId === 'browser') {
      if (sessionStorage.getItem('time')) {
        const data = JSON.parse(sessionStorage.getItem('time'));
        this.db.dispatch({type: RESTORE_DELIVERY_TIME, payload: data});
      }
    }
  }

  setPaymentMethod(data) {
    this.db.dispatch({type: SAVE_PAYMENT_METHOD, payload: data});
    this.paymentMethod.subscribe(resp => {
      if (resp) {
        if (this.platformId === 'browser') {
          sessionStorage.setItem('payment_method', JSON.stringify(resp));
        }
      }
    });
  }
  setExistingPaymentMethod() {
    if (this.platformId === 'browser') {
      if (sessionStorage.getItem('time')) {
        const data = JSON.parse(sessionStorage.getItem('payment_method'));
        this.db.dispatch({type: RESTORE_PAYMENT_METHOD, payload: data});
      }
    }
  }

  resetCartAfterPlacingOrder() {
    if (this.platformId === 'browser') {
      sessionStorage.clear();
      this.db.dispatch({type: CLEAR});
      this.db.dispatch({type: CLEAR_SELECTED_POLICY});
      this.db.dispatch({type: CLEAR_DELIVERY_TIME});
      this.db.dispatch({type: CLEAR_AVAILABLE_PROMOTION});
      this.db.dispatch({type: CLEAR_ACCEPTED_PROMOTION});
      this.db.dispatch({type: CLEAR_ORDER_NOTES});
      this.db.dispatch({type: CLEAR_PAYMENT_METHOD});
    }
  }

  saveBookingInfo(data) {
    this.db.dispatch({type: BOOKING, payload: data});
  }

  updateUser(data) {
    this.db.dispatch({type: UPDATE_USER, payload: data});
  }

  editUserInformation(data) {
    this.editUserData.emit(data);
  }

  storeCommonData(data) {
    this.db.dispatch({type: VIEW_ITEM_MODAL, payload: data});
  }


  setOrderNotes(data) {
    this.db.dispatch({type: SAVE_ORDER_NOTES, payload: data});
    this.orderNotes.subscribe(resp => {
      if (resp) {
        if (this.platformId === 'browser') {
          sessionStorage.setItem('orderNotes', JSON.stringify(resp));
        }
      }
    });
  }
  storeExistingOrderNotes() {
    if (this.platformId === 'browser') {
      if (sessionStorage.getItem('orderNotes')) {
        const data = JSON.parse(sessionStorage.getItem('orderNotes'));
        this.db.dispatch({type: RESTORE_ORDER_NOTES, payload: data});
      }
    }
  }

  setParcelAdds(data) {
    this.db.dispatch({type: _SAVE_PARCEL_INFO, payload: data});
  }

  setDeliveryChargeInfo(data) {
    this.db.dispatch({type: _SAVE_DELIVERY_CHARGE, payload: data});
  }

  resetSignUpForm() {
    this.signUpForm.emit(true);
  }


  setConfirmOrder(){
    this.confirmOrderUponAccept.emit(true);
  }
  setBookingProgress(s) {
    this.bookingProgress.emit(s);
  }

  setOrderProgress(s){
    this.orderProgress.emit(s);
  }
  setErrorsData(data) {
    this.errors_data.emit(data);
  }

   pageRedirection(user?) {
    if (this.platformId === 'browser') {
      this.route_page.subscribe(res => {
        if (Object.getOwnPropertyNames(res).length !== 0) {
          if (user && (user.mobile_no && user.mobile_no === '')) {
            this.router.navigateByUrl('my-setting');
          } else {
            this.router.navigateByUrl((res.page && res.page !== '') ? res.page : 'my-account').then(() => {
              if(res.page === 'reservation' && (user && user.provider)) {
                let currentUrl = this.router.url;
                this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                    this.router.navigate([currentUrl]);
                });
              }
            })
          }
        } else {
          this.router.navigateByUrl('my-account');
        }
      });
    }
  }
}
