import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inArray'
})
export class InArrayPipe implements PipeTransform {

  transform(array: any[], value: any, objectKey: string): boolean {
    if(!array || array.length <= 0) {
      return
    }
    return array.find(item => item[objectKey] === value);
  }

}
