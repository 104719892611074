import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pictureSrcset'
})
export class pictureSrcsetPipe implements PipeTransform {

  transform(fileName: string, device?: string): any {
    const path = fileName.split(".").slice(0, -1).join(".");
    const ext = fileName.substr(fileName.lastIndexOf('.') + 1);
    let returnValue = path.concat('.').concat(ext.toString());
    if(device) {
      returnValue = path.concat('-').concat(device.toString()).concat('.').concat(ext.toString());
    }
    // console.log(returnValue)
    return returnValue;   
  }

}

