export const AVAILABLE_PROMOTION = 'AVAILABLE_PROMOTION';
export const RESTORE_AVAILABLE_PROMOTION = 'RESTORE_AVAILABLE_PROMOTION';
export const CLEAR_AVAILABLE_PROMOTION = 'CLEAR_AVAILABLE_PROMOTION';

export function Reducer(state: any = [], action) {
  switch (action.type) {
    case AVAILABLE_PROMOTION:
      return action.payload;
    case RESTORE_AVAILABLE_PROMOTION:
      return action.payload; 
    case CLEAR_AVAILABLE_PROMOTION:
      return [];
    default:
      return state;
  }
}
