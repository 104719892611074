export const GENERATE_SCHEDULE = 'GENERATE_SCHEDULE';

export function scheduleReducer(state: any = {}, action) {
  switch (action.type) {
    case GENERATE_SCHEDULE:
      return action.payload;
    default:
      return state;
  }
}
