export const SET_ROUTE_PAGE = 'SET_ROUTE_PAGE';
export const REMOVE_ROUTE = 'REMOVE_ROUTE';

export function routeReducer(state: any = {}, action) {
  switch (action.type) {
    case SET_ROUTE_PAGE:
      return action.payload;
    case REMOVE_ROUTE:
      return {};
    default:
      return state;
  }
}
