export const ADD_ITEM = 'ADD_ITEM';
export const UPDATE_QUANTITY = 'UPDATE_QUANTITY';
export const DELETE_ITEM = 'DELETE_ITEM';
export const RESTORE_EXISTING_DATA = 'RESTORE_EXISTING_DATA';
export const CLEAR = 'CLEAR';

export function reducer(state = [], action) {
  switch (action.type) {
    case 'ADD_ITEM':
      return [...state, action.payload];
    case 'UPDATE_QUANTITY':
      return state.map((item, index) => {
        if (index !== action.payload.index) {
          return item
        }
        return {
          ...item,
          ...action.payload.dish
        }
      });
    case 'DELETE_ITEM':
      return state.filter((item, index) => index !== action.payload);
    case RESTORE_EXISTING_DATA:
      return action.payload;    
    case CLEAR:
      return [];  
    default:       
      return state;
  }
}

