export const SELECTED_DISH = 'SELECTED_DISH';
export const CLEAR = 'CLEAR';

export function reducer(state: any = {}, action) {
  switch (action.type) {
    case 'SELECTED_DISH':
      return action.payload;
    case CLEAR:
      return {};
    default:
      return state;
  }
}
