import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { StoreService } from './store.service';
import { Md5 } from 'ts-md5/dist/md5';
import { of } from 'rxjs';

@Injectable()
export class SharedService {
  today = new Date();
  restaurantSchedule: any;
  restaurantData: any;
  restaurantDish: any;
  selected_policy: string;

  // res_vat_rate: number;
  // res_vat_type: string;

  constructor(
    private store: StoreService,
    @Inject(PLATFORM_ID) private readonly platformId: any
  ) {
    if (this.platformId === 'browser') {
      this.store.restaurantData.subscribe(res => {
        if (Object.getOwnPropertyNames(res).length !== 0) {
          this.restaurantSchedule = res.restaurant_data['schedule'];
          this.restaurantData = res.restaurant_data;
          // this.res_vat_rate = Number((res.restaurant_data['vat_rate'] !== null ? res.restaurant_data['vat_rate'] : 0).toFixed(2));
          // this.res_vat_type = res.restaurant_data['is_vat_included'] === true ? 'include' : 'exclude';
        }
      });
      // this.store.restaurantMenu.subscribe(res => {
      //   if (Object.getOwnPropertyNames(res).length !== 0) {
      //     this.restaurantDish = res['dishes_information'];
      //   }
      // });
      if (sessionStorage.getItem('policy')) {
        this.selected_policy = JSON.parse(sessionStorage.getItem('policy')).policy;
      }
    }
  }


  scheduleProcessing(selectedPolicy: string = this.selected_policy) {
    return new Promise<any>((resolve, reject) => {
      const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const dayName = days[new Date().getDay()];
      if (this.restaurantSchedule && this.restaurantSchedule.length > 0) {
        const schedule = this.restaurantSchedule.find(f => f.day_name === dayName);
        if (!schedule) return false;
        let now = this.getMinutesNow();
        let timesArray = [];
        let matchCurrentTimeSlot;
        let unmatchTimeSlots = [];
        let opening_hours = '';
        let data = {};

        schedule['order_schedule'].forEach((schedule, index) => {
          if (!schedule['is_active']) return;
          schedule = Object.assign({}, schedule, {
            delivery_time: this.restaurantData['is_delivery_time_extended'] === true ? this.restaurantData['default_delivery_time'] : schedule['delivery_time'],
            collection_time: this.restaurantData['is_collection_time_extended'] ? this.restaurantData['default_collection_time'] : schedule['collection_time'],
          });
          let policyTime = (selectedPolicy && selectedPolicy === 'DELIVERY') ? schedule['delivery_time'] : schedule['collection_time'];
          let minOrderAmount = (selectedPolicy && selectedPolicy === 'DELIVERY') ? schedule['min_delivery_amount'] : schedule['min_collection_amount'];

          let last_time = (selectedPolicy === 'DELIVERY') ? (schedule['delivery_last_time'] ? schedule['delivery_last_time'] : null) : (schedule['collection_last_time'] ? schedule['collection_last_time'] : null);

          let opening_time24 = this.convertTime12to24(schedule['opening_time']);
          let closing_time24 = this.convertTime12to24(last_time ? last_time : schedule['closing_time']);

          let start = this.getMinutes(opening_time24);
          let end = this.getMinutes(closing_time24);
          if ((now < end) && (schedule[selectedPolicy === 'DELIVERY' ? 'is_delivery' : 'is_collection'])) {
            let opening_time = new Date(new Date().setHours(Number(opening_time24.split(':')[0]), Number(opening_time24.split(':')[1])));
            let closing_time = new Date(new Date().setHours(Number(closing_time24.split(':')[0]), Number(closing_time24.split(':')[1])));
            //adding policy time with opening and closing
            opening_time = new Date(opening_time.setMinutes(opening_time.getMinutes() + policyTime));
            // check opening time is current shift or not
            if ((start < now) && (now < end)) {
              matchCurrentTimeSlot = schedule;
              opening_time = new Date(new Date().setMinutes(new Date().getMinutes() + policyTime));
            } else {
              unmatchTimeSlots.push(schedule);
            }
            let times = this.generateTimeList(opening_time, closing_time);
            times.forEach(element => {
              timesArray.push({ time: element, delivery_time: policyTime, minOrderAmount: minOrderAmount });
            });
          }
          //opening hours
          opening_hours = opening_hours.concat(' | ' + (schedule.opening_time + ' - ' + schedule.closing_time));
          data['opening' + (index + 1)] = schedule.opening_time;
          data['closing' + (index + 1)] = schedule.closing_time;
        });

        if (timesArray && timesArray.length > 0) {
          data['status'] = matchCurrentTimeSlot ? 'OPEN' : 'PRE-ORDER',
            data['shift'] = matchCurrentTimeSlot ? matchCurrentTimeSlot.shift_name : unmatchTimeSlots[0].shift_name,
            data['delivery_type'] = matchCurrentTimeSlot ? ['ASAP', 'TODAY'] : ['TODAY'],
            data['asap'] = matchCurrentTimeSlot ? timesArray[0].time : null,
            data['today'] = timesArray,
            data['min_collection_amount'] = matchCurrentTimeSlot ? matchCurrentTimeSlot.min_collection_amount : unmatchTimeSlots[0].min_collection_amount,
            data['min_delivery_amount'] = matchCurrentTimeSlot ? matchCurrentTimeSlot.min_delivery_amount : unmatchTimeSlots[0].min_delivery_amount,
            data['collection_time'] = matchCurrentTimeSlot ? matchCurrentTimeSlot.collection_time : unmatchTimeSlots[0].collection_time,
            data['delivery_time'] = matchCurrentTimeSlot ? matchCurrentTimeSlot.delivery_time : unmatchTimeSlots[0].delivery_time

          data['is_ordering'] = this.restaurantData['is_ordering'];
          data['is_delivery'] = this.restaurantData['is_ordering'] && this.restaurantData['is_delivery'] ? true : false;
          data['is_collection'] = this.restaurantData['is_ordering'] && this.restaurantData['is_collection'] ? true : false;
          data['is_reservation'] = this.restaurantData['is_reservation'];

          if (this.restaurantData['is_collection_closed_for_today'] === true && this.restaurantData['is_delivery_closed_for_today'] === true) data['is_ordering'] = false;
          if (this.restaurantData['is_collection_closed_for_today'] === true) data['is_collection'] = false;
          if (this.restaurantData['is_delivery_closed_for_today'] === true) data['is_delivery'] = false;
          if (data['is_collection'] === false && data['is_delivery'] === false) data['is_ordering'] = false;

          data['opening_hours'] = opening_hours.replace('\|', '').trim();
          this.store.saveSchedule(data);
          resolve(data);
        } else {
          let data = {
            status: this.restaurantData['is_reservation'] ? 'OPEN' : 'CLOSED',
            is_reservation: this.restaurantData['is_reservation'],
            is_ordering: this.restaurantData['is_ordering']
          }
          this.store.saveSchedule(data);
          resolve(data);
        }
      } else {
        reject(null);
      }
    });
  }


  validateUserSelectedTime(userSelectedTimeObject: any): void {
    if (userSelectedTimeObject && sessionStorage.getItem('policy')) {
      let currentTime = new Date();
      this.selected_policy = JSON.parse(sessionStorage.getItem('policy')).policy;
      const timeIn24 = this.convertTime12to24(userSelectedTimeObject.when);
      let [hour, min] = timeIn24.split(":");
      let userTimeDate = new Date().setHours(Number(hour), Number(min), 0);
      /**
       * IF TIME ALREADY PASSED AWAY
       * AND USER BACK TO PLACE ORDER
      */
      if (currentTime >= new Date(userTimeDate)) {
        /**
         * As time is gone, re-generate schedule
         * **/
        this.scheduleProcessing(this.selected_policy);
        this.store.schedule.subscribe(res => {
          if (Object.getOwnPropertyNames(res).length !== 0) {
            this.setDeliveryTime(res);
          }
        });
      } else {
        /**
         * - USER IN TIME
         * - FIND HOW LONG HAVE PASSED 
         * - IF PASSWED MAX NUM OF TIME
         * - check schdeule policy time in num 
         * - RE-ASSIGN ASAP TIME AUTOMATICALLY
         * 
        */

        let difference = currentTime.getTime() - new Date(userTimeDate).getTime(); // This will give difference in milliseconds
        let resultInMinutes = Math.round(difference / 60000);
        let policyData = JSON.parse(sessionStorage.getItem('policy'));
        this.store.schedule.subscribe(res => {
          if (Object.getOwnPropertyNames(res).length !== 0) {
            if (resultInMinutes < policyData.delivery_time) {
              this.setDeliveryTime(res);
            }
          }
        });

      }
    }
  }

  setDeliveryTime(timeObject: any): void {
    let time = {
      status: timeObject.status === 'OPEN' ? 'ASAP' : 'PRE-ORDER',
      when: timeObject.today[0].time,
      res_status: timeObject.status
    }
    this.store.storeDeliveryTime(time);
    if (this.platformId === 'browser') {
      sessionStorage.setItem('time', JSON.stringify(time));
    }
  }

  convertTime24to12 = (time24) => {
    const [sHours, minutes] = time24.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
    const period = +sHours < 12 ? 'AM' : 'PM';
    const hours = +sHours % 12 || 12;

    return `${hours}:${minutes} ${period}`;
  }
  convertTime12to24 = (time12) => {
    const [sHours, minutes, period] = time12.match(/([0-9]{1,2}):([0-9]{2}) (AM|PM)/).slice(1);
    const PM = period === 'PM';
    const hours = (+sHours % 12) + (PM ? 12 : 0);

    return `${('0' + hours).slice(-2)}:${minutes}`;
  }

  getMinutes(time24h) {
    var time = time24h.split(':');
    return time[0] * 60 + time[1] * 1;
  }

  getMinutesNow() {
    var timeNow = new Date();
    let time = timeNow.getHours() * 60 + timeNow.getMinutes();
    return time;
  }

  isToday(date) {
    const today = new Date();
    if (today.toDateString() === date.toDateString()) {
      return true;
    }
    return false;
  }

  hoursToMinutes(hours: number) {
    if (hours) {
      return (hours * 60);
    }
    return 0;
  }

  public BookingScheduleProcessing(date: string, advanceHours?: number, timeGap?: number) {
    let selectedDate = new Date(date)
    return new Promise((resolve, reject) => {
      const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const dayName = days[new Date(selectedDate).getDay()];
      if ((this.restaurantSchedule && this.restaurantSchedule.length > 0) && this.restaurantData['is_reservation']) {
        const schedule = this.restaurantSchedule.find(f => f.day_name === dayName);
        if (!schedule || !schedule['reservation_schedule'] || schedule['reservation_schedule'].length <= 0) {
          let _msg = "<strong>it\'s a holiday, You can select onther date</strong> <br>Please contact with restaurant if you have any inquiry";
          resolve({ status: 'CLOSED', msg: _msg });
          return false;
        }
        let now: number = this.isToday(selectedDate) ? (this.getMinutesNow() + this.hoursToMinutes(advanceHours)) : 0;
        if (now >= 1440) {
          let _msg = advanceHours ? `You have to book before ${advanceHours} hours ago.` : 'We are fully book today.';
          resolve({ status: 'CLOSED', msg: _msg });
          return false;
        }
        let timesArray = [];
        schedule['reservation_schedule'].forEach((schedule, index) => {
          if (!schedule['is_active']) return false;
          let opening_time24 = this.convertTime12to24(schedule['opening_time']);
          let closing_time24 = this.convertTime12to24(schedule['closing_time']);

          let start = this.getMinutes(opening_time24);
          let end = this.getMinutes(closing_time24);
          if (now < end) {
            let opening_time = new Date(new Date(selectedDate).setHours(Number(opening_time24.split(':')[0]), Number(opening_time24.split(':')[1])));
            let closing_time = new Date(new Date(selectedDate).setHours(Number(closing_time24.split(':')[0]), Number(closing_time24.split(':')[1])));
            if ((start < now) && (now < end)) {
              let min = this.hoursToMinutes(advanceHours);
              opening_time = new Date(new Date(selectedDate).setMinutes(new Date().getMinutes() + min));
            }
            let newTimeGap = timeGap ? (schedule.max_time_gap_between_slot ? schedule.max_time_gap_between_slot : timeGap) : undefined;
            let times = this.generateTimeList(opening_time, closing_time, newTimeGap);
            times.forEach(element => {
              timesArray.push(element);
            });
          }
        });
        resolve(timesArray);
      } else {
        reject(null);
      }
    });
  }


  private generateTimeList(opening, closing, gapTime: number = 15) {
    const start = new Date(opening);
    const end = new Date(closing);
    let minutesArray: any[] = [];
    for (let i = 1; i < 60; i++) {
      if ((gapTime * i) > 60) break;
      minutesArray.push(gapTime * i);
    }
    const newMinutes = minutesArray.find(item => item >= start.getMinutes());
    start.setMinutes(newMinutes);
    var arr = [];
    while (start <= end) {
      let time = this.convertTime24to12(start.toTimeString().substring(0, 5))
      arr.push(time);
      start.setMinutes(start.getMinutes() + gapTime);
    }
    return arr;
  }

  private changeTimeFormat(date) {
    // IT CONVERTS TO 24 HOUR TIME FORMATE TO 12 HOUR TIME FORMAT
    const h = date.getHours();
    let m = date.getMinutes();
    m = ('0' + m).slice(-2);
    let _time = '';
    if (h >= 12) {
      _time = (h > 12) ? ('0' + (h - 12)).slice(-2) + ':' + m + ' ' + 'PM' : ('0' + h).slice(-2) + ':' + m + ' ' + 'PM';
    } else {
      _time = ('0' + h).slice(-2) + ':' + m + ' ' + 'AM';
    }
    return _time;
  }

  checkPromotionEligibility(totalAmount, discountsData, offersData) {
    /*  Default = 1
     Only Collection = 2
     Only Delivery = 3
     Both (Collection & Delivery) = 4
     First Order = 5
     Cash Only = 6
     Card = 7
     others = 8*/

    return new Promise(resolve => {
      const currentTime = this.today;
      let offers = [];
      let discount = [];
      if (this.platformId === 'browser') {
        let selected_policy;
        let user;
        let selected_payment;
        /*@Override@*/
        this.store.selected_policy.subscribe((result: any) => {
          if (result && result['policy']) {
            selected_policy = result['policy'] === 'COLLECTION' ? 1 : 2;
          } else {
            selected_policy = 2;
          }
        });
        this.store.user.subscribe((result: any) => {
          if (result && result['isLogged'] === true) {
            user = result['data'];
          }
        });
        this.store.paymentMethod.subscribe((result: any) => {
          if (result && result['is_active']) {
            selected_payment = result['paymentType'] === 'CARD' ? 2 : 1
          }
        });
        setTimeout(() => {
          if (offersData.length > 0) {
            /**
             * COLLECTION AND DELIVERY BOTH= 0
             * COLLECTION = 1
             * DELIVERY = 2
             * **/
            const offData = offersData.filter(item =>
              item.its_type === 2
              && totalAmount >= item.offer_eligible_amount
              && item.is_active === true
              && (item.platform === 0 || item.platform === 1 || item.platform === 4 || item.platform === 5)
            );

            if (offData.length > 0) {
              offData.forEach(item => {
                if (Number(item.offer_eligible_for) === selected_policy || Number(item.offer_eligible_for) === 0) {
                  /**
                   * - CHECK ELIGIBLE PAYMENT METHODS CASH = 1, CARD = 2, BOTH = 0
                   * - CHECK DISCOUNT START DATE AND END DATE IF NO DATE FIXED THEN IT'S FOR ALL DAY
                   * - CHECK DISCOUNT AVAILABLE TIMES
                   * - CHECK DEFAULT (AUTOMATIC ACCEPT DISCOUNT)
                   * - CHECK IS IT FIRST ORDER BY CUSTOMER
                   * **/

                  if (selected_payment) {
                    if (item.eligible_payment_methods === selected_payment || item.eligible_payment_methods === 0) {
                      /*
                        * - EITHER CASH OR CARD * CASH = 1; CARD = 2
                        * */

                      if (item.start_date !== null && item.end_date !== null) {
                        /**
                         * - IF START AND END DATE ASSIGNED
                         */
                        const start_date = new Date(item.start_date);
                        const end_date = new Date(item.end_date);
                        if (currentTime >= start_date && currentTime <= end_date) {
                          if (item.offer_available_time.length > 0) {
                            const dayName = currentTime.getDay();
                            const _times = item.offer_available_time.filter(book => book.day_name === dayName);
                            if (_times.length > 0) {
                              const start_time = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + _times[0].start_time));
                              const end_time = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + _times[0].end_time));
                              if (currentTime >= start_time && currentTime <= end_time || currentTime < start_time) {
                                if (item.is_default) {
                                  /*** - COUNT USER PREVIOUS ORDERS * **/
                                  if (item.is_first_order && (user && user.total_orders === 0)) {
                                    offers.push(item);
                                  }

                                  if (item.is_first_order === false) {
                                    offers.push(item);
                                  }
                                  // default discount added directly
                                  if (!sessionStorage.getItem('promoId')) {
                                    this.store.saveAcceptedPromotion(item);
                                  }
                                } else {
                                  /**
                                   * - NOT DEFAULT
                                   * - USER HAVE TO ACCEPT DISCOUNT
                                   * **/

                                  if (item.is_first_order && (user && user.total_orders === 0)) {
                                    offers.push(item);
                                  }

                                  if (item.is_first_order === false) {
                                    offers.push(item);
                                  }
                                }
                              }
                            } // end time compare
                          } else {
                            /**
                             * - NOT TIME GIVEN
                             * **/
                            if (item.is_default) {
                              /*** - COUNT USER PREVIOUS ORDERS * **/
                              if (item.is_first_order && (user && user.total_orders === 0)) {
                                offers.push(item);
                              }

                              if (item.is_first_order === false) {
                                offers.push(item);
                              }
                              // default discount added directly
                              if (!sessionStorage.getItem('promoId')) {
                                this.store.saveAcceptedPromotion(item);
                              }
                            } else {
                              /**
                               * - NOT DEFAULT * - USER HAVE TO ACCEPT DISCOUNT
                               * **/

                              if (item.is_first_order && (user && user.total_orders === 0)) {
                                offers.push(item);
                              }

                              if (item.is_first_order === false) {
                                offers.push(item);
                              }
                            }
                          }
                        }
                      } else {
                        /*
                          * - IF START AND END DATE NOT GIVEN.
                          * */
                        if (item.offer_available_time.length > 0) {
                          const dayName = currentTime.getDay();
                          const _times = item.offer_available_time.filter(book => book.day_name === dayName);
                          if (_times.length > 0) {
                            const start_time = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + _times[0].start_time));
                            const end_time = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + _times[0].end_time));
                            if (currentTime >= start_time && currentTime <= end_time || currentTime < start_time) {
                              if (item.is_default) {
                                /*** - COUNT USER PREVIOUS ORDERS * **/
                                if (item.is_first_order && (user && user.total_orders === 0)) {
                                  offers.push(item);
                                }

                                if (item.is_first_order === false) {
                                  offers.push(item);
                                }
                                // default discount added directly
                                if (!sessionStorage.getItem('promoId')) {
                                  this.store.saveAcceptedPromotion(item);
                                }
                              } else {
                                /**
                                 * - NOT DEFAULT
                                 * - USER HAVE TO ACCEPT DISCOUNT
                                 * **/

                                if (item.is_first_order && (user && user.total_orders === 0)) {
                                  offers.push(item);
                                }

                                if (item.is_first_order === false) {
                                  offers.push(item);
                                }
                              }
                            }
                          } // end time compare
                        } else {
                          if (item.is_default) {
                            /**
                             * - COUNT USER PREVIOUS ORDERS
                             * **/
                            if (item.is_first_order && (user && user.total_orders === 0)) {
                              offers.push(item);
                            }

                            if (item.is_first_order === false) {
                              offers.push(item);
                            }
                            // default discount added directly
                            if (!sessionStorage.getItem('promoId')) {
                              this.store.saveAcceptedPromotion(item);
                            }
                          } else {
                            /**
                             * - NOT DEFAULT
                             * - USER HAVE TO ACCEPT DISCOUNT
                             * **/

                            if (item.is_first_order && (user && user.total_orders === 0)) {
                              offers.push(item);
                            }

                            if (item.is_first_order === false) {
                              offers.push(item);
                            }
                          }
                        }
                      }
                    }
                  } else {
                    /**
                     * - IF USER DID NOT SELECT PAYMENT METHOD YET!
                     * **/

                    /*
                      * - EITHER CASH OR CARD * CASH = 1; CARD = 2
                      * */

                    if (item.start_date !== null && item.end_date !== null) {
                      /**
                       * - IF START AND END DATE ASSIGNED
                       */
                      const start_date = new Date(item.start_date);
                      const end_date = new Date(item.end_date);
                      if (currentTime >= start_date && currentTime <= end_date) {
                        if (item.offer_available_time.length > 0) {
                          const dayName = currentTime.getDay();
                          const _times = item.offer_available_time.filter(book => book.day_name === dayName);
                          if (_times.length > 0) {
                            const start_time = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + _times[0].start_time));
                            const end_time = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + _times[0].end_time));
                            if (currentTime >= start_time && currentTime <= end_time || currentTime < start_time) {
                              if (item.is_default) {
                                /*** - COUNT USER PREVIOUS ORDERS * **/
                                if (item.is_first_order && (user && user.total_orders === 0)) {
                                  offers.push(item);
                                }

                                if (item.is_first_order === false) {
                                  offers.push(item);
                                }
                                // default discount added directly
                                if (!sessionStorage.getItem('promoId')) {
                                  this.store.saveAcceptedPromotion(item);
                                }
                              } else {
                                /**
                                 * - NOT DEFAULT
                                 * - USER HAVE TO ACCEPT DISCOUNT
                                 * **/

                                if (item.is_first_order && (user && user.total_orders === 0)) {
                                  offers.push(item);
                                }

                                if (item.is_first_order === false) {
                                  offers.push(item);
                                }
                              }
                            }
                          } // end time compare
                        } else {
                          /**
                           * - NOT TIME GIVEN
                           * **/
                          if (item.is_default) {
                            /*** - COUNT USER PREVIOUS ORDERS * **/
                            if (item.is_first_order && (user && user.total_orders === 0)) {
                              offers.push(item);
                            }

                            if (item.is_first_order === false) {
                              offers.push(item);
                            }
                            // default discount added directly
                            if (!sessionStorage.getItem('promoId')) {
                              this.store.saveAcceptedPromotion(item);
                            }
                          } else {
                            /**
                             * - NOT DEFAULT * - USER HAVE TO ACCEPT DISCOUNT
                             * **/

                            if (item.is_first_order && (user && user.total_orders === 0)) {
                              offers.push(item);
                            }

                            if (item.is_first_order === false) {
                              offers.push(item);
                            }
                          }
                        }
                      }
                    } else {
                      /*
                        * - IF START AND END DATE NOT GIVEN.
                        * */
                      if (item.offer_available_time.length > 0) {
                        const dayName = currentTime.getDay();
                        const _times = item.offer_available_time.filter(book => book.day_name === dayName);
                        if (_times.length > 0) {
                          const start_time = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + _times[0].start_time));
                          const end_time = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + _times[0].end_time));
                          if (currentTime >= start_time && currentTime <= end_time || currentTime < start_time) {
                            if (item.is_default) {
                              /*** - COUNT USER PREVIOUS ORDERS * **/
                              if (item.is_first_order && (user && user.total_orders === 0)) {
                                offers.push(item);
                              }

                              if (item.is_first_order === false) {
                                offers.push(item);
                              }
                              // default discount added directly
                              if (!sessionStorage.getItem('promoId')) {
                                this.store.saveAcceptedPromotion(item);
                              }
                            } else {
                              /**
                               * - NOT DEFAULT
                               * - USER HAVE TO ACCEPT DISCOUNT
                               * **/

                              if (item.is_first_order && (user && user.total_orders === 0)) {
                                offers.push(item);
                              }

                              if (item.is_first_order === false) {
                                offers.push(item);
                              }
                            }
                          }
                        } // end time compare
                      } else {
                        if (item.is_default) {
                          /**
                           * - COUNT USER PREVIOUS ORDERS
                           * **/
                          if (item.is_first_order && (user && user.total_orders === 0)) {
                            offers.push(item);
                          }

                          if (item.is_first_order === false) {
                            offers.push(item);
                          }
                          // default discount added directly
                          if (!sessionStorage.getItem('promoId')) {
                            this.store.saveAcceptedPromotion(item);
                          }
                        } else {
                          /**
                           * - NOT DEFAULT
                           * - USER HAVE TO ACCEPT DISCOUNT
                           * **/

                          if (item.is_first_order && (user && user.total_orders === 0)) {
                            offers.push(item);
                          }

                          if (item.is_first_order === false) {
                            offers.push(item);
                          }
                        }
                      }
                    }
                  }
                } else {
                  offers = [];
                  this.store.saveAcceptedPromotion({});
                }
              });
            }
          }

          if (discountsData.length > 0) {
            /**
             * COLLECTION AND DELIVERY BOTH= 0
             * COLLECTION = 1
             * DELIVER = 2
             * **/
            const dis = discountsData.filter(item =>
              item.its_type === 1
              && totalAmount >= item.discount_eligible_amount
              && item.is_active === true
              && (item.platform === 0 || item.platform === 1 || item.platform === 4 || item.platform === 5)
            );
            if (dis.length > 0) {

              dis.forEach(item => {
                if (Number(item.discount_eligible_for) === selected_policy || Number(item.discount_eligible_for) === 0) {
                  /**
                   * - CHECK ELIGIBLE PAYMENT METHODS CASH = 1, CARD = 2, BOTH = 0
                   * - CHECK DISCOUNT START DATE AND END DATE IF NO DATE FIXED THEN IT'S FOR ALL DAY
                   * - CHECK DISCOUNT AVAILABLE TIMES
                   * - CHECK DEFAULT (AUTOMATIC ACCEPT DISCOUNT)
                   * - CHECK IS IT FIRST ORDER BY CUSTOMER
                   * **/

                  if (selected_payment) {
                    if (item.eligible_payment_methods === selected_payment || item.eligible_payment_methods === 0) {
                      /*
                        * - EITHER CASH OR CARD * CASH = 1; CARD = 2
                        * */

                      if (item.start_date !== null && item.end_date !== null) {
                        /**
                         * - IF START AND END DATE ASSIGNED
                         */
                        const start_date = new Date(item.start_date);
                        const end_date = new Date(item.end_date);
                        if (currentTime >= start_date && currentTime <= end_date) {

                          if (item.discount_available_time.length > 0) {

                            const dayName = currentTime.getDay();
                            const _times = item.discount_available_time.filter(book => book.day_name === dayName);
                            if (_times.length > 0) {
                              const start_time = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + _times[0].start_time));
                              const end_time = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + _times[0].end_time));
                              if (currentTime >= start_time && currentTime <= end_time || currentTime < start_time) {
                                if (item.is_default) {

                                  /*** - COUNT USER PREVIOUS ORDERS * **/
                                  if (item.is_first_order && (user && user.total_orders === 0)) {
                                    discount.push(item);
                                  }
                                  if (item.is_first_order === false) {
                                    discount.push(item);
                                  }
                                  // default discount added directly
                                  if (!sessionStorage.getItem('promoId')) {
                                    this.store.saveAcceptedPromotion(item);
                                  }
                                } else {
                                  /**
                                   * - NOT DEFAULT
                                   * - USER HAVE TO ACCEPT DISCOUNT
                                   * **/

                                  /*** - COUNT USER PREVIOUS ORDERS * **/
                                  if (item.is_first_order && (user && user.total_orders === 0)) {
                                    discount.push(item);
                                  }
                                  if (item.is_first_order === false) {
                                    discount.push(item);
                                  }
                                }
                              } // end time compare
                            }
                          } else {
                            /**
                             * - NOT TIME GIVEN
                             * **/
                            if (item.is_default) {
                              /*** - COUNT USER PREVIOUS ORDERS * **/
                              /*** - COUNT USER PREVIOUS ORDERS * **/
                              if (item.is_first_order && (user && user.total_orders === 0)) {
                                discount.push(item);
                              }
                              if (item.is_first_order === false) {
                                discount.push(item);
                              }
                              // default discount added directly
                              if (!sessionStorage.getItem('promoId')) {
                                this.store.saveAcceptedPromotion(item);
                              }
                            } else {
                              /**
                               * - NOT DEFAULT * - USER HAVE TO ACCEPT DISCOUNT
                               * **/


                              /*** - COUNT USER PREVIOUS ORDERS * **/
                              if (item.is_first_order && (user && user.total_orders === 0)) {
                                discount.push(item);
                              }
                              if (item.is_first_order === false) {
                                discount.push(item);
                              }
                            }
                          }
                        }
                      } else {
                        /*
                          * - IF START AND END DATE NOT GIVEN (if it is NULL).
                          * - PROCESS WITH TIME ELSE DEFAULT
                          * */

                        if (item.discount_available_time.length > 0) {
                          const dayName = currentTime.getDay();
                          const _times = item.discount_available_time.filter(book => book.day_name === dayName);
                          if (_times.length > 0) {
                            const start_time = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + _times[0].start_time));
                            const end_time = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + _times[0].end_time));
                            if (currentTime >= start_time && currentTime <= end_time || currentTime < start_time) {
                              if (item.is_default) {
                                /*** - COUNT USER PREVIOUS ORDERS * **/
                                if (item.is_first_order && (user && user.total_orders === 0)) {
                                  discount.push(item);
                                }
                                if (item.is_first_order === false) {
                                  discount.push(item);
                                }
                                // default discount added directly
                                if (!sessionStorage.getItem('promoId')) {
                                  this.store.saveAcceptedPromotion(item);
                                }

                              } else {
                                /**
                                 * - NOT DEFAULT
                                 * - USER HAVE TO ACCEPT DISCOUNT
                                 * **/

                                /*** - COUNT USER PREVIOUS ORDERS * **/
                                if (item.is_first_order && (user && user.total_orders === 0)) {
                                  discount.push(item);
                                }
                                if (item.is_first_order === false) {
                                  discount.push(item);
                                }
                              }
                            }
                          } // end time compare
                        } else {
                          if (item.is_default) {
                            /**
                             * - COUNT USER PREVIOUS ORDERS
                             * **/
                            /*** - COUNT USER PREVIOUS ORDERS * **/
                            if (item.is_first_order && (user && user.total_orders === 0)) {
                              discount.push(item);
                            }
                            if (item.is_first_order === false) {
                              discount.push(item);
                            }
                            // default discount added directly
                            if (!sessionStorage.getItem('promoId')) {
                              this.store.saveAcceptedPromotion(item);
                            }
                          } else {
                            /**
                             * - NOT DEFAULT
                             * - USER HAVE TO ACCEPT DISCOUNT
                             * **/

                            /*** - COUNT USER PREVIOUS ORDERS * **/
                            if (item.is_first_order && (user && user.total_orders === 0)) {
                              discount.push(item);
                            }
                            if (item.is_first_order === false) {
                              discount.push(item);
                            }
                          }
                        }
                      }
                    }
                  } else {
                    /**
                     * - IF USER DID NOT SELECT PAYMENT METHOD YET!
                     * **/

                    /*
                      * - EITHER CASH OR CARD * CASH = 1; CARD = 2
                      * */


                    if (item.start_date !== null && item.end_date !== null) {
                      /**
                       * - IF START AND END DATE ASSIGNED
                       */


                      const start_date = new Date(item.start_date);
                      const end_date = new Date(item.end_date);
                      if (currentTime >= start_date && currentTime <= end_date) {

                        if (item.discount_available_time.length > 0) {
                          const dayName = currentTime.getDay();
                          const _times = item.discount_available_time.filter(book => book.day_name === dayName);
                          if (_times.length > 0) {

                            const start_time = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + _times[0].start_time));
                            const end_time = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + _times[0].end_time));
                            if (currentTime >= start_time && currentTime <= end_time || currentTime < start_time) {
                              if (item.is_default) {
                                /*** - COUNT USER PREVIOUS ORDERS * **/
                                if (item.is_first_order && (user && user.total_orders === 0)) {
                                  discount.push(item);
                                }
                                if (item.is_first_order === false) {
                                  discount.push(item);
                                }
                                // default discount added directly
                                if (!sessionStorage.getItem('promoId')) {
                                  this.store.saveAcceptedPromotion(item);
                                }

                              } else {
                                /**
                                 * - NOT DEFAULT
                                 * - USER HAVE TO ACCEPT DISCOUNT
                                 * **/

                                /*** - COUNT USER PREVIOUS ORDERS * **/
                                if (item.is_first_order && (user && user.total_orders === 0)) {
                                  discount.push(item);
                                }
                                if (item.is_first_order === false) {

                                  discount.push(item);
                                }
                              }
                            }
                          } // end time compare
                        } else {
                          /**
                           * - NOT TIME GIVEN
                           * **/
                          if (item.is_default) {
                            /*** - COUNT USER PREVIOUS ORDERS * **/
                            /*** - COUNT USER PREVIOUS ORDERS * **/
                            if (item.is_first_order && (user && user.total_orders === 0)) {
                              discount.push(item);
                            }
                            if (item.is_first_order === false) {
                              discount.push(item);
                            }
                            // default discount added directly
                            if (!sessionStorage.getItem('promoId')) {
                              this.store.saveAcceptedPromotion(item);
                            }
                          } else {
                            /**
                             * - NOT DEFAULT * - USER HAVE TO ACCEPT DISCOUNT
                             * **/

                            /*** - COUNT USER PREVIOUS ORDERS * **/
                            if (item.is_first_order && (user && user.total_orders === 0)) {
                              discount.push(item);
                            }

                            if (item.is_first_order === false) {
                              discount.push(item);
                            }
                          }
                        }
                      }
                    } else {
                      /*
                        * - IF START AND END DATE NOT GIVEN.
                        * */
                      if (item.discount_available_time.length > 0) {
                        const dayName = currentTime.getDay();
                        const _times = item.discount_available_time.filter(book => book.day_name === dayName);
                        if (_times.length > 0) {
                          const start_time = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + _times[0].start_time));
                          const end_time = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + _times[0].end_time));
                          if (currentTime >= start_time && currentTime <= end_time || currentTime < start_time) {
                            if (item.is_default) {
                              /*** - COUNT USER PREVIOUS ORDERS * **/
                              /*** - COUNT USER PREVIOUS ORDERS * **/
                              if (item.is_first_order && (user && user.total_orders === 0)) {
                                discount.push(item);
                              }
                              if (item.is_first_order === false) {
                                discount.push(item);
                              }
                              // default discount added directly
                              if (!sessionStorage.getItem('promoId')) {
                                this.store.saveAcceptedPromotion(item);
                              }
                            } else {
                              /**
                               * - NOT DEFAULT
                               * - USER HAVE TO ACCEPT DISCOUNT
                               * **/

                              /*** - COUNT USER PREVIOUS ORDERS * **/
                              if (item.is_first_order && (user && user.total_orders === 0)) {
                                discount.push(item);
                              }
                              if (item.is_first_order === false) {
                                discount.push(item);
                              }
                            }
                          }
                        } // end time compare
                      } else {
                        if (item.is_default) {
                          /**
                           * - COUNT USER PREVIOUS ORDERS
                           * **/
                          /*** - COUNT USER PREVIOUS ORDERS * **/
                          if (item.is_first_order && (user && user.total_orders === 0)) {
                            discount.push(item);
                          }
                          if (item.is_first_order === false) {
                            discount.push(item);
                          }
                          // default discount added directly
                          if (!sessionStorage.getItem('promoId')) {
                            this.store.saveAcceptedPromotion(item);
                          }
                        } else {
                          /**
                           * - NOT DEFAULT
                           * - USER HAVE TO ACCEPT DISCOUNT
                           * **/

                          /*** - COUNT USER PREVIOUS ORDERS * **/
                          if (item.is_first_order && (user && user.total_orders === 0)) {
                            discount.push(item);
                          }
                          if (item.is_first_order === false) {
                            discount.push(item);
                          }
                        }
                      }
                    }
                  }
                }
              });
            }
          }
          const availableOffersDiscount = discount.concat(offers);
          resolve(availableOffersDiscount);
        }, 200);
      }
    });
  }


  getASAPTime(deliveryTime) {
    const currentTime = new Date();
    return this.changeTimeFormat(new Date(currentTime.getTime() + Number(deliveryTime) * 60000));
  }

  stringTitleCase(str) {
    return str.toLowerCase().split(' ').map(function (word) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
  }

  goToWebLink(data) {
    var url = String(data).replace(/^(https?:\/\/)?(www\.)?/, 'http://');
    window.open(url, '_blank',);
  }

  generateCartHashKey(dishData) {
    let dish_extra_id = '';
    if (dishData['dish_extra']) {
      dishData['dish_extra'].forEach(grp => {
        dish_extra_id = dish_extra_id.concat(grp['_id']);
        grp['option'].forEach(opt => {
          dish_extra_id = dish_extra_id.concat(opt['_id']);
        });
      });
    }
    const checksum_data = {
      _id: dishData['_id'],
      dish_id: dishData['dish_id'],
      dish_instruction: dishData['dish_instruction'].trim().toLowerCase(),
      dish_extra_id: dish_extra_id
    }
    const md5 = new Md5();
    let checksum = md5.appendStr(JSON.stringify(checksum_data)).end();
    return checksum;
  }

  generateCartDataHashKey(cartData: any, _for?: string) {
    const md5 = new Md5();
    let checksum = md5.appendStr(JSON.stringify(cartData)).end();
    if (_for && _for === 'reservation-menu') {
      sessionStorage.setItem('generated_res_cart_data', JSON.stringify(checksum));
    } else {
      sessionStorage.setItem('generated_cart_data', JSON.stringify(checksum));
    }
  }

  getAvailablePromotions(subTotalExcludeOffer, discountsData, offersData) {
    return new Promise((resolve) => {
      let eligiblePromotionList = [];
      this.checkPromotionEligibility(subTotalExcludeOffer, discountsData, offersData).then((result: any) => {
        result.forEach(item => {
          eligiblePromotionList.push(item);
        });
        this.store.saveAvailablePromotion(eligiblePromotionList);
        resolve('check & save');
      });
    });
  }

  getAcceptedDiscount(subTotalExcludeOffer, AcceptedData) {
    return new Promise((resolve) => {
      /** its_type=1=discount, its_type=2=offer **/
      let selected_policy;
      let selected_payment;
      this.store.selected_policy.subscribe((result: any) => {
        if (result && result['policy']) {
          selected_policy = result['policy'] === 'COLLECTION' ? 1 : 2;
        } else {
          selected_policy = 2;
        }
      });
      this.store.paymentMethod.subscribe((result: any) => {
        if (result && result['is_active']) {
          selected_payment = result['paymentType'] === 'CARD' ? 2 : 1
        }
      });
      switch (AcceptedData.its_type) {
        case 1:
          if (
            (selected_policy === Number(AcceptedData.discount_eligible_for) || Number(AcceptedData.discount_eligible_for) === 0)
            && selected_payment
            && (AcceptedData.eligible_payment_methods === selected_payment || AcceptedData.eligible_payment_methods === 0)
            && subTotalExcludeOffer >= AcceptedData.discount_eligible_amount
          ) {
            /**
             * SET ACCEPTED DISCOUNT DATA
             * Eligible policy either collection/delivery or both
             * col=1, del =2, both=0,
             * Eligible payment method selected
             * cash=1, card 2, both 0
            **/
            resolve(AcceptedData);
          } else if (
            (selected_policy === Number(AcceptedData.discount_eligible_for) || Number(AcceptedData.discount_eligible_for) === 0)
            && !selected_payment
            && subTotalExcludeOffer >= AcceptedData.discount_eligible_amount
          ) {
            /**-----SET ACCEPTED DISCOUNT DATA UNTIL USER SELECT PAYMENT METHOD-----**/
            resolve(AcceptedData);
          } else if (
            (selected_policy === Number(AcceptedData.discount_eligible_for) || Number(AcceptedData.discount_eligible_for) === 0)
            && selected_payment
            && (AcceptedData.eligible_payment_methods !== selected_payment || AcceptedData.eligible_payment_methods !== 0)
            && subTotalExcludeOffer < AcceptedData.discount_eligible_amount
          ) {
            /**
             * RESET ACCEPTED DISCOUNT DATA
             * SELECTED PAYMENT METHOD NOT ELIBIBLE
            **/
            this.store.saveAcceptedPromotion({});
          } else if (
            (selected_policy !== Number(AcceptedData.discount_eligible_for) || Number(AcceptedData.discount_eligible_for) !== 0)
            && !selected_payment
            || subTotalExcludeOffer < AcceptedData.discount_eligible_amount
          ) {
            /**
             * RESET ACCEPTED DISCOUNT DATA
             * SELECTED policy NOT ELIBIBLE
            **/
            this.store.saveAcceptedPromotion({});
          } else if (
            (selected_policy !== Number(AcceptedData.discount_eligible_for) || Number(AcceptedData.discount_eligible_for) !== 0)
            && selected_payment
            && (AcceptedData.eligible_payment_methods !== selected_payment || AcceptedData.eligible_payment_methods !== 0)
            || subTotalExcludeOffer < AcceptedData.discount_eligible_amount
          ) {
            /**
             * RESET ACCEPTED DISCOUNT DATA
             * SELECTED PAYMENT METHOD NOT ELIBIBLE
            **/
            this.store.saveAcceptedPromotion({});
          } else if (
            subTotalExcludeOffer < AcceptedData.discount_eligible_amount
          ) {
            this.store.saveAcceptedPromotion({});
          }
          break;

        case 2:
          if (
            (selected_policy === Number(AcceptedData.offer_eligible_for) || Number(AcceptedData.offer_eligible_for) === 0)
            && selected_payment
            && (AcceptedData.eligible_payment_methods === selected_payment || AcceptedData.eligible_payment_methods === 0)
            && subTotalExcludeOffer >= AcceptedData.offer_eligible_amount
          ) {
            /**
             * SET ACCEPTED DISCOUNT DATA
             * Eligible policy either collection/delivery or both
             * col=1, del =2, both=0,
             * Eligible payment method selected
             * cash=1, card 2, both 0
            **/
            resolve(AcceptedData);
          } else if (
            (selected_policy === Number(AcceptedData.offer_eligible_for) || Number(AcceptedData.offer_eligible_for) === 0)
            && !selected_payment
            && subTotalExcludeOffer >= AcceptedData.offer_eligible_amount
          ) {
            /**SET ACCEPTED DISCOUNT DATA UNTIL USER SELECT PAYMENT METHOD**/
            resolve(AcceptedData);
          } else if (
            (selected_policy === Number(AcceptedData.offer_eligible_for) || Number(AcceptedData.offer_eligible_for) === 0)
            && selected_payment
            && (AcceptedData.eligible_payment_methods !== selected_payment || AcceptedData.eligible_payment_methods !== 0)
            || subTotalExcludeOffer < AcceptedData.offer_eligible_amount
          ) {
            /**
             * RESET ACCEPTED DISCOUNT DATA
             * SELECTED PAYMENT METHOD NOT ELIBIBLE
            **/
            this.store.saveAcceptedPromotion({});
          } else if (
            (selected_policy !== Number(AcceptedData.offer_eligible_for) || Number(AcceptedData.offer_eligible_for) !== 0)
            && !selected_payment
            || subTotalExcludeOffer < AcceptedData.offer_eligible_amount
          ) {
            /**
             * RESET ACCEPTED DISCOUNT DATA
             * SELECTED policy NOT ELIBIBLE
            **/
            this.store.saveAcceptedPromotion({});
          } else if (
            (selected_policy !== Number(AcceptedData.offer_eligible_for) || Number(AcceptedData.offer_eligible_for) !== 0)
            && selected_payment
            && (AcceptedData.eligible_payment_methods !== selected_payment || AcceptedData.eligible_payment_methods !== 0)
            || subTotalExcludeOffer < AcceptedData.offer_eligible_amount
          ) {
            /**
             * RESET ACCEPTED DISCOUNT DATA
             * SELECTED PAYMENT METHOD NOT ELIBIBLE
            **/
            this.store.saveAcceptedPromotion({});
          } else if (
            subTotalExcludeOffer < AcceptedData.offer_eligible_amount
          ) {
            this.store.saveAcceptedPromotion({});
          }
          break;

      }
    });
  }


  public calculateDishVat(dishData: any, discountData: any, totalDish: number) {
    if (!totalDish || totalDish <= 0) return;
    // check vat is enable or not
    if (!this.restaurantData || !this.restaurantData['is_vat_enable']) return;
    // check has discout or not
    // discount amount deduction from dish vat
    let deductedAmount: number = 0;
    if (discountData['amount'] && discountData['amount'] > 0) {
      if (discountData['type'] === 1) {// dish vat deduction by percentage
        deductedAmount = Number(((dishData['unit_price'] * discountData['rate']) / 100).toFixed(4));
      } else {// dish vat deduction by fixed
        deductedAmount = discountData['amount'] / totalDish;
      }
    }
    // declare the property
    let price: number = Number((((dishData['unit_price'] - deductedAmount)) - dishData['package_price']) * dishData['quantity']);
    let vatType: string = this.restaurantData.is_vat_included ? 'include' : 'exclude';
    let vatRate: number = dishData['vat_rate'] ? dishData['vat_rate'] : this.findUpperLabelVatRate(dishData['category_id']);
    // vat formula
    let includeFormula = Number(((price / ((100 + vatRate) / 100)) * (vatRate / 100)).toFixed(4));
    let excludeFormula = Number(((price * vatRate) / 100).toFixed(4));
    let vatableAmount: number = vatType === 'include' ? Number(includeFormula.toFixed(4)) : Number(excludeFormula.toFixed(4));
    return { vat_amount: vatableAmount, vat_type: vatType, vat_rate: vatRate };
  }

  findUpperLabelVatRate(categoryId: string): number {
    let vat_rate: number = 0;
    const dishCategory = this.restaurantDish.flatMap(cuisine => cuisine.dish_categories).find(category => category._id === categoryId);
    if (dishCategory && dishCategory.vat_rate) {
      return vat_rate = dishCategory.vat_rate;
    } else if (this.restaurantData.vat_rate) {
      return vat_rate = this.restaurantData.vat_rate;
    }
    return vat_rate;
  }


}
