export const config = {
  API: 'https://devoretapi.co.uk/api/v1/website/', //live
  API2: 'https://devoretapi.co.uk/api/v1/', //live
  POSTCODE_URL: 'https://services.postcodeanywhere.co.uk/Capture/Interactive/', //test
  SERVICE_KEY: '',
  YOUR_SITE_KEY: '6LeoKfYUAAAAAId21NioS_8F-POKWr8XHXljdY6F',
  YOUR_SECRET_KEY: '6LeoKfYUAAAAAIfMnfhUC9cMQKb7aZKshA_lHV8E',
  Google_OAuth_Client_Id: '188035853745-jt0eqi1ng0fhjfst507vooitcamcgvfc.apps.googleusercontent.com',
  Facebook_App_Id: '889782398193217',
  social_login: {
    is_active: false,
    facebook: {
      is_active: true
    },
    google: {
      is_active: true
    }
  },
  guest_login: true,
  bag_charge: {
    value: 0,
    title: 'Bag Charge',
    message: 'The fee for plastic shopping bags in England will be doubled to 10p and extended to all shops from April 2021.'
  },

  RES_LOCATION: 'GB', // for united kingdom: GB, Bangladesh: BD

 // RESTAURANT_ID: '61b6fa7db0f0f8e3bc44c7d9', //Test Restaurant
  // RESTAURANT_ID: '5e9e85bfd0d8b1375e6b12d4', // Koloshi Takeaway
  // RESTAURANT_ID: '5c6974efc8bd250a10b572a0', //Test Restaurant
  // RESTAURANT_ID: '5da70294fb57190da0fbf8ee', //o-play
  // RESTAURANT_ID: '5c7374ac6e4804645f9345ae', //Rajbari
  // RESTAURANT_ID: '5cdfd3c24c9b1e61c3c63f06', //Chilli Indian Takeaway
  // RESTAURANT_ID: '5d8c57191397fb05ea8d05fd', //Brick Lane Brasserie
  // RESTAURANT_ID: '5c8f8238946618050834b27f', //The Rajasthan
  // RESTAURANT_ID: '5cdfd3c24c9b1e61c3c63f06', //Chilli Indian Takeaway
  // RESTAURANT_ID: '5d8c57191397fb05ea8d05fd', //Brick Lane Brasserie
  // RESTAURANT_ID: '5cb56f4155fa51463d9ff583', //EVEREST BRASSERIE
  // RESTAURANT_ID: '5c8f8238946618050834b27f', //The Rajasthan
  // RESTAURANT_ID: '5c57052fa2e01d10167edb6a', //Rajdoot
  // RESTAURANT_ID: '5cda73c010bff011fce384d2', //Yoshida Sushi
  // RESTAURANT_ID: '5ef875f6fd42e602de6caeb7', // kushboo
  // RESTAURANT_ID: '5d625a13749864664fe4d7b4', // Eastern Eye
  // RESTAURANT_ID: '5f82dc67f2baf603796fa38d', // Koloshi Takeaway
  // RESTAURANT_ID: '5f82dc67f2baf603796fa38d', // Koloshi Takeaway
  // RESTAURANT_ID: '5fa94484d92093048d23c8c2', // The Indian Takeaway
  // RESTAURANT_ID: '5fc11800c7a2c20a0f0dbeff', // TIndia Restaurant & Takeaway
  // RESTAURANT_ID: '5f435f0190d8802cce3b0e5f', // Cinnamon Brick Lane Restaurant
  // RESTAURANT_ID: '5e9e860cd0d8b1375e6b12dc', // Claypot Lounge
  // RESTAURANT_ID: '5e84aac2e84a33028c3d9c1d', // Coriander
  // RESTAURANT_ID: '5fb39659c0bd605afba71e66', // The Mogul Manningtree
  // RESTAURANT_ID: '5d2c52f1aabff20431fa0a36', // Sushi World
    RESTAURANT_ID: '5c6e9ba3bfbcf336a072c4b1'
};
