<!-- footbar start-->
<div class="container-fluid" id="footer">
  <div class="row">
    <div class="container">
      <div class="column-list">
        <div class="column info-column">
          <p class="title">Contact Us</p>
          <li>
            <span class="svg-icon"><i class="flaticon-placeholder-1" ></i></span>
            <p class="location">
              <span class="tag">Address</span><br>
              <a href="{{'https://www.google.com/maps/search/'+restaurant_address}}" target="_blank">{{restaurant_address}}</a>
            </p>
          </li>
          <li class="contacts" *ngIf="(data | async)?.restaurant_data?.business_tel && (data | async)?.restaurant_data?.business_tel !==''">
            <span class="svg-icon"><i class="flaticon-phone" ></i></span>
            <p>
              <span class="tag">Call us now</span><br>
              <span *ngFor="let item of (data | async)?.restaurant_data?.business_tel | split">
                <a href="tel:{{item.trim()}}">{{item.trim()}}</a><br>
              </span>
            </p>
          </li>
          <li class="email" *ngIf="(data | async)?.restaurant_data?.email && (data | async)?.restaurant_data?.email !==''">
            <span class="svg-icon"><i class="flaticon-envelope" ></i></span>
            <p>
              <span class="tag">Send an email</span><br>
              <a href="mailto:{{(data | async)?.restaurant_data?.email}}">{{(data | async)?.restaurant_data?.email}}</a>
            </p>
          </li>
          <li class="email" *ngIf="(data | async)?.restaurant_data?.domain !==''">
            <span class="svg-icon"><i class="flaticon-worldwide-1" ></i></span>
            <p>
              <span class="tag">Website</span><br>
              <a href="{{(data | async)?.restaurant_data?.domain}}">{{(data | async)?.restaurant_data?.domain}}</a>
            </p>
          </li>
          <p class="social-btns mb-3" *ngIf="(data | async)?.restaurant_data?.social_media && (data | async)?.restaurant_data?.social_media?.length > 0">
            <a *ngFor="let item of (data | async)?.restaurant_data?.social_media" href="{{item?.link}}" target="_blank" class="btn {{item.media_name}}">
              <span class="ico"><i class="flaticon-{{item.media_name}}" ></i></span>
            </a>
          </p>
        </div>
        <div class="column schedule-column">
          <p class="title">Opening Hours</p>
          <li class="schedules" *ngFor="let day of (data | async)?.restaurant_data?.schedule"
          [ngClass]="{'active': day.day_name === (today | date: 'EEEE') }">
            <span class="day">{{day.day_name}}</span>
            <span class="time">
              <li *ngFor="let time of day.default">
                <span class="text-lowercase" *ngIf="time.is_active">{{time.opening_time}} to {{time.closing_time}}</span>
              </li>
              <li *ngIf="day?.default?.length === 0">
                <span>closed</span>
              </li>
            </span>
          </li>
        </div>
        <div class="column cuisine-column">
          <p class="title">Cuisine Type</p>
          <li class="cuisine">
            <span *ngFor="let item of (data | async)?.restaurant_data?.cuisine_name | split">{{item.trim() | titlecase}}</span>
          </li>
          <legend class="divider"></legend>
          <p class="title">Quick Links</p>
          <li *ngIf="(data | async)?.restaurant_content?.order_online_page?.is_active">
            <a class="link" [routerLink]="['/order-online']">Order</a>
          </li>
          <li *ngIf="(data | async)?.restaurant_content?.booking_page?.is_active">
            <a class="link" [routerLink]="['/reservation']">Reservation</a>
          </li>
          <li *ngIf="(data | async)?.restaurant_content?.deals_page?.is_active">
            <a class="link" [routerLink]="['/deals']">Deals</a>
          </li>
          <li *ngIf="(data | async)?.restaurant_content?.review_page?.is_active">
            <a class="link" [routerLink]="['/reviews']">Review</a>
          </li>
          <li><a class="link" [routerLink]="['/terms-conditions']">Terms & Conditions</a></li>
          <li><a class="link" [routerLink]="['/privacy-policy']">Privacy Policy</a></li>
        </div>
        <div class="column delivery-column">
          <ng-container *ngIf="(data | async)?.restaurant_data?.is_delivery && areas?.length > 0">
            <p class="title">Delivery Areas</p>
            <li class="areas" *ngFor="let item of areas | slice:0:2;">
              <ng-container *ngIf="item?.is_radius">
                <span *ngIf="item?.delivery_charge === 0">Free Delivery</span>
                <span *ngIf="item?.delivery_charge > 0">Delivery Charge {{item.delivery_charge |  CustomCurrency}}</span>
                : Up-to {{item?.maximum_delivery_area}} mile radius
              </ng-container>
              <ng-container *ngIf="item?.is_radius === false">
                {{item?.area_name.toUpperCase()}} {{item?.area_postcode.toUpperCase()}} -
                <span *ngIf="item?.delivery_charge > 0"> Delivery Charge {{item?.delivery_charge |  CustomCurrency}}</span>
                <span *ngIf="item?.delivery_charge === 0"> Free Delivery</span>
              </ng-container>
            </li>
            <li *ngIf="areas?.length >= 3">
              <button type="button" class="btn btn-link p-0" data-toggle="modal" data-target="#deliveryAreas">
                See More <span class="icon end"><i class="flaticon-fast-forward-double-right-arrows-symbol"></i></span>
              </button>
            </li>
            <legend class="divider"></legend>
          </ng-container>
          <p class="title">Technical help</p>
          <li><a class="link" href="http://devoret.com/contact/" target="_blank">Contact Us</a></li>
          <li><a class="link" [routerLink]="['/faq']">FAQ</a></li>
          <legend class="divider"></legend>          
          <p class="card-text mt-3 lh-1"><small>Subscribe to our newsletter for good deals, sent out every month.</small></p>
          <form [formGroup]="form" (ngSubmit)="addUserSubs(form.value)">
            <div class="input-group mt-2">
              <input type="text" class="form-control" placeholder="Email..." formControlName="subscribed_email"
                [ngClass]="{'is-invalid': f.subscribed_email.touched && f.subscribed_email.errors}">
              <div class="input-group-append">
                <button class="btn btn-sm btn-primary" type="submit">Subscribe</button>
              </div>
              <div class="invalid-feedback" *ngIf="f.subscribed_email.touched && f.subscribed_email.errors">
                <li *ngIf="f.subscribed_email.errors.required">Email is required.</li>
                <li *ngIf="f.subscribed_email.errors.pattern">Enter valid email address.</li>
                <li *ngIf="f.subscribed_email.errors.email">Enter valid email address.</li>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- footbar end-->

<!-- footer start-->
<div class="container-fluid" id="footbar">
  <div class="container">
    <span class="copyright-text">
      Devoret Partner Restaurant. ©{{today | date: 'yyyy'}} <a href="http://devoret.co.uk/">Devoret</a>. All Rights Reserved
    </span>
    <span class="links">
      <a href="http://devoret.com/" target="_blank">Become a partner restaurant</a>
    </span>
  </div>
</div>
<!-- footer end-->


<div class="modal fade" id="deliveryAreas" tabindex="-1" role="dialog" aria-labelledby="deliveryAreasTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deliveryAreasTitle">Delivery Areas</h5>
        <button type="button" class="btn btn-sm btn-outline-danger rounded-circle" data-dismiss="modal">
          <span class="icon"><i class="flaticon-unchecked" ></i></span>
        </button>
      </div>
      <div class="modal-body">
        <ul class="list-group list-group-flush">
          <li class="list-group-item px-0" *ngFor="let item of areas">
            <span *ngIf="item?.is_radius">
              <span *ngIf="item?.delivery_charge === 0">Free Delivery</span>
              <span *ngIf="item?.delivery_charge > 0">
                Delivery Charge <b>{{item.delivery_charge |  CustomCurrency}}</b>
              </span>
              : Up-to {{item?.maximum_delivery_area}} mile radius
            </span>
            <span *ngIf="item?.is_radius === false">
              {{item?.area_name.toUpperCase()}} {{item?.area_postcode.toUpperCase()}} -
              <span *ngIf="item?.delivery_charge > 0"> Delivery Charge <b>{{item?.delivery_charge |  CustomCurrency}}</b></span>
              <span *ngIf="item?.delivery_charge === 0"> Free Delivery</span>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
