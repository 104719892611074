import * as rst from '../actions/restaurant';
export const CLEAR_RES_DATA = 'CLEAR_RES_DATA';

export function reducer(state: any = {}, action: rst.RestaurantData) {
  switch (action.type) {
    case rst.SAVE_RESTAURANT_DATA:
      return action.payload;
    case CLEAR_RES_DATA:
      return {};
    default:
      return state;
  }
}
