import {Injectable,  Inject, PLATFORM_ID} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

declare var gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private router: Router, @Inject(PLATFORM_ID) private readonly platformId: any) {
  }

  public event(eventName: string, params: {}) {
    gtag('event', eventName, params);
  }

  public init(googleAnalyticsKey) {
    if (this.platformId === 'browser') {
      this.listenForRouteChanges(googleAnalyticsKey);

      try {
        const script1 = document.createElement('script');
        script1.async = true;
        script1.src = 'https://www.googletagmanager.com/gtag/js?id=' + googleAnalyticsKey;
        document.head.appendChild(script1);

        const script2 = document.createElement('script');
        script2.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '` + googleAnalyticsKey + `', {'send_page_view': true});
    `;
        document.head.appendChild(script2);
      } catch (ex) {
        console.error('Error appending google analytics');
        console.error(ex);
      }
    }
  }

  private listenForRouteChanges(googleAnalyticsKey) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', googleAnalyticsKey, {
          'page_path': event.urlAfterRedirects,
        });
      }
    });
  }

  public createSchema(schemaCodes) {
    if (this.platformId === 'browser') {
      const script3 = document.createElement('script');
      script3.type = 'application/ld+json';
      script3.innerHTML = schemaCodes;
      document.head.appendChild(script3);
    }
  }
}
