import * as fromRestaurant from './restaurant';
import * as fromCart from './cart';
import * as fromSelectedDish from './selected_dish';
import * as isLogin from './signInReducer';
import * as policy from './policyReducer';
import * as fromRoute from './routeReducer';
import * as customerAddreess from './customer_delivery_address_reducer';
import * as availablePromotion from './available_promotion';
import * as acceptPromotion from './accepted_promotion';
import * as fromSchedule from './schedule';
import * as fromBooking from './bookingReducer';
import * as fromDeliveryTime from './deliveryTimeReducer';
import * as fromImageviewer from './common_modal_reducer';
import * as bookingTimes from './booking_times';
import * as paymentMethod from './paymentMethodReducer';
import * as orderNotes from './orderNotesReducer';
import * as deliveryCharge from './delivery_charge_reducer';
import * as parcelInfo from './parcelReducer';


export const reducers = {
  restaurant: fromRestaurant.reducer,
  cart: fromCart.reducer,
  selected_dish: fromSelectedDish.reducer,
  user: isLogin.loginReducer,
  selected_policy: policy.userSelectedPolicy,
  route_page: fromRoute.routeReducer,
  delivery_address: customerAddreess.reducer,
  delivery_time: fromDeliveryTime.deliveryTimeSave,
  available_promotion: availablePromotion.Reducer,
  accepted_promotion: acceptPromotion.Reducer,
  schedule: fromSchedule.scheduleReducer,
  booking: fromBooking.reducer,
  booking_time_list: bookingTimes.Reducer,
  common_data: fromImageviewer.reducer,
  paymentMethod: paymentMethod.SetSelectedPaymentMethod,
  orderNotes: orderNotes.saveOrderNotes,
  deliveryCharge: deliveryCharge._setDeliveryCharge,
  parcelInformation: parcelInfo.ParcelReducer
};
